/* Custom fonts below */

//Ubuntu - Google Font
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500,700');

//Ubuntu - Google Font
@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i');

// Font Awesome
@import '../node_modules/font-awesome/scss/font-awesome.scss';
