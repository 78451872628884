.navbar{
    .header-wrap {
        height: 100px;
        @include transition($transition-navbar);

        @include media-breakpoint-down(md) {
            height: 70px;
            padding-left: 0;
        }
    }

    .navbar-brand {
        padding: 0;
        height: 80px;
        @include transition($transition-navbar);

        @include media-breakpoint-down(md) {
            height: 38px;
            margin-right: 0;
        }

        a, .logo {
            display: initial;
        }
    }

    &.navbar-fixed-top {
        border-bottom: 0;
    }

    &.sticky {
        .navbar-toggler {
            display: block;
        }

        .social, .navbar-collapse:not(.show) {
            display: none !important;
        }

        .navbar-collapse.in {
            display: block !important;
        }

        .header-wrap {
            height:70px;
        }

        .navbar-brand {
            height: 48px;
        }
    }

    &.nav-burger {
        ul.navbar-nav {
            width: 100%;
        }
    }

    .navbar-toggler {
        .icon-bar {
            &, &:before, &:after {
                height: 2px;
                background-color: $brand-color;
            }
        }
    }
}

.container-header {
    background: #F8F8FA;
    .cloud-logo.has-image-placeholder {
        height: 100px;
        width: 200px;
        position: relative;
        box-sizing: border-box;
        box-shadow: 0 0 0 1px #ddd;
        &.cloud-editable {
            margin: 0 !important;
            padding: 0 !important;
        }
    }

    .header-wrap {
        /* overflow: hidden; */
        padding: 0 3%;
        border-bottom: 1px solid $border-color;
        .header-left {
            margin: 10px;
            margin-right: 0;
            @media (max-width:$screen-sm-max) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .header-right {
            margin: 10px;
            margin-left: 0;
            .social {
                text-align: right;
                a {
                    color: $brand-color-2;
                    &:hover {
                        color: $brand-color-2;
                        text-decoration: none;
                    }
                }
                span {
                    color: $brand-color-2;
                    display: inline-block;
                    margin-right: 10px;
                }
                ul {
                    display: inline-block;
                }
                @media (max-width:$screen-sm-max) {
                    display: none;
                }
            }

            .account {
                    display: flex;
                    align-items: center;

                    .button {
                    padding-top: 0;
                    padding-bottom: 0;
                    span {
                        display: inline-block;
                        .btn {
                            border-radius: 3px;

                            @include media-breakpoint-down(md) {
                                font-size: 0;
                                line-height: 1;
                                padding: 12px;

                                i {
                                    font-size: 1rem;
                                }
                            }
                        }
                        .bb-content {
                            padding: 0;
                            margin: 0;
                            &.cloud-editable {
                                padding: 0;
                                margin: 0;
                            }
                        }

                        &.logup {
                            @include media-breakpoint-down(md) {
                                .btn-primary {
                                    background-color: transparent !important;
                                    background-repeat: no-repeat;
                                    background-size: 20px;
                                    background-position: 45% 45%;
                                    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2138.327 2138.327'%3E%3Cpath d='M1032.925 1852.654c0 98.076-79.506 177.583-177.583 177.583S677.76 1950.73 677.76 1852.654s79.506-177.583 177.583-177.583 177.582 79.507 177.582 177.583zm541.462-177.582c-98.076 0-177.583 79.507-177.583 177.583s79.507 177.583 177.583 177.583 177.583-79.507 177.583-177.583c-.001-98.077-79.507-177.583-177.583-177.583zm551.232-833.649l-218.512 649.464s-18.088 96.383-110.247 96.383H693.471c-109.982 0-114.604-120.809-114.604-120.809S461.624 596.637 455.814 544.088s-73.013-91.498-73.013-91.498L93.651 317.522c-158.306-82.784-86.349-240.298 0-204.121 366.652 172.961 536.973 258.122 547.932 326.25 11.091 68.26 30.367 232.64 30.367 232.64v1.056c2.245-.66 3.829-1.056 3.829-1.056h1351.477c163.191 0 98.363 169.397 98.363 169.132zm-316.084 361.635l-2.245.132H737.57L759.619 1378h997.896l52.02-174.942zm104.173-352.524H693.339l23.502 186.825h1141.678l55.189-186.825z' fill='%23c41330'/%3E%3C/svg%3E");

                                    i {
                                        color: $primary-color !important;
                                        opacity: 0;
                                        visibility: hidden;
                                    }
                                }
                            }
                        }
                        &.login {
                            padding-left: 15px;
                            border-left: 1px solid $brand-color-2;
                            margin-left: 10px;
                            a {
                                background: $brand-color-2;
                                border-color: $brand-color-2;

                                @include media-breakpoint-down(md) {
                                    padding: 12px 15px;
                                }
                            }
                        }
                    }

                    .logup, .login {
                        .btn {
                            font-weight: 400;
                        }
                    }
                }

                .connected-user {
                    .dropdown-toggle {
                        color: $nav-text-color;
                        cursor: pointer;
                        padding: 10px;
                        &:after {
                            content: "";
                            border: solid $nav-text-color;
                            border-width: 0 1px 1px 0;
                            display: inline-block;
                            padding: 2px;
                            margin-left: 10px;
                            position: relative;
                            top: -3px;
                            @include transform(rotate(45deg));
                        }
                    }
                    .dropdown-menu {
                        box-shadow: none;
                        border-radius: 0;
                        margin-top: 0;
                        a {
                            color: $nav-text-color;
                            padding: 10px 20px;
                        }
                    }
                    .dropdown, .user-avatar {
                        display: inline-block;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                .navbar-toggler {
                    display: none !important;
                }
            }
        }

        .btn {
            padding: 8px 16px;
            text-transform: uppercase;
        }
    }

    .navbar-wrapper {
        background: $menu-bg;
        padding: 0 3%;
        .navbar-nav {
            margin: 10px 0;
            li {
                    font-size: .875rem;
                    margin-left: 0 !important;
                a, .nav-link {
                    height: inherit;
                    line-height: inherit;
                    font-weight: bold;
                    display: block;
                    text-transform: uppercase;
                    font-size: .8rem;
                }

                &:not(:first-child) {
                    margin-left: 5px;
                }

                &:not(:last-child) {
                    margin-right: 5px;
                }

                &:not(.menu-add-parameter) > a, &:not(.menu-add-parameter) > .nav-link {
                    color: $nav-text-color;
                    padding: 8px 10px;
                    border: 1px solid transparent;
                    @media (min-width:$screen-lg-min) {
                        padding: 10px 14px;
                    }
                    &:hover {
                        background-color: $white;
                        color: $nav-text-color;
                    }
                }
                &.active > .nav-link  {
                    color: $white;
                    background-color: $brand-color;
                    &:hover {
                        color: $nav-text-color;
                    }
                }

                &.active > a:after {
                    display: none;
                }
            }

            li.has-children {
                &:hover {
                    > a {
                        background: $white;
                        margin-bottom: 0;
                        border: 1px solid $border-color;
                        border-bottom-color: transparent;
                        color: $nav-text-color;
                    }
                }
                & > .nav-link:after {
                    content: "";
                    border: solid $nav-text-color;
                    border-width: 0 1px 1px 0;
                    display: inline-block;
                    padding: 2px;
                    margin-left: 4px;
                    position: relative;
                    top: -3px;
                    @include transform(rotate(45deg));
                }
                &.active {
                    & > .nav-link:after {
                        border-color: $white;
                    }
                    &:hover {
                        & > .nav-link:after {
                            border-color: $nav-text-color;
                        }
                    }
                }
                .submenu {
                    border: 1px solid $border-color;
                    border-top: 0;

                    li {
                        border-top: 0;
                        margin-right: 0;
                        a {
                            border-bottom: 1px solid $border-color;
                            @media (max-width:$screen-sm-max) {
                                border-bottom: 0;
                            }

                            &:hover {
                                color: $brand-color;
                                padding-bottom: 10px;
                            }
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        background-color: $white;
                    }

                    &.multi-colomn {
                        display: flex;
                        width: 40rem;

                        @include media-breakpoint-down(md) {
                            flex-direction: column;
                        }

                        ul {
                            flex: 1;

                            &:not(:last-child) {
                                border-right: 1px solid $border-color;
                            }

                            li a {
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.logo.has-image-placeholder {
    height: 110px;
}

@include media-breakpoint-up(lg) {
    .stage-wrapper-outer {
        margin-left: $menu-shortcut-width;
        .navbar {
            margin-left: $menu-shortcut-width;
        }
    }
}

@media (max-width: 992px) {
    .navbar-nav {
        .show-submenu + .submenu {
            display: none;
        }

        .submenu.show {
            display: block;
        }
    }
}
