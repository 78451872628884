//switch
.group-switch {
  position: relative; width: 130px;
  -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none; user-select: none;
}

.group-switch-checkbox {
  display: none;
}

.group-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid $border-color;
  border-radius: 3px;
  font-weight: normal;
}
.group-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.group-switch-inner:before, .group-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;
  line-height: 36px;
  font-size: 14px;
  color: white;
  font-family: $font-sans;
  text-transform: uppercase;
  box-sizing: border-box;
  background-clip: padding-box;
  -moz-box-shadow: inset 0px 0px 10px 1px rgba(97, 97, 97, 0.20);
  -webkit-box-shadow: inset 0px 0px 10px 1px rgba(97, 97, 97, 0.20);
  -o-box-shadow: inset 0px 0px 10px 1px rgba(97, 97, 97, 0.20);
  box-shadow: inset 0px 0px 10px 1px rgba(97, 97, 97, 0.20);
}
.group-switch-inner:before {
  content: "";
  padding-left: 5px;
  background-color: #C41330;
  color: $white;
}
.group-switch-inner:after {
  content: "";
  padding-right: 5px;
  background-color: $gray-lighter;
  color: $text-color;
  text-align: right;
}
.group-switch-switch {
  border: 1px solid $border-color;
  border-radius: 3px;
  display: block;
  width: 22px;
  margin: 0px;
  background: $white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 108px;
  transition: all 0.3s ease-in 0s;
}
.group-switch-checkbox:checked + .group-switch-label .group-switch-inner {
  margin-left: 0;
}
.group-switch-checkbox:checked + .group-switch-label {
  border-color: $brand-color;
  .group-switch-switch {
      right: 0px;
      border-color: $brand-color;
  }
}