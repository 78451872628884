/* Custom variables below */

$menu-bg:                       #E8EBF0;
$nav-text-color:                #545C67;
$bg-footer:                     #495466;
$bg-footer-copyright:           #373f4c;
$brand-primary:                 $primary-color;

$menu-shortcut-width:           50px;

//== Typography
$font-size-base:                1rem;

//VAR for Styling
$text-color:                    #5F5F5F;
$black:                         #000;
$white:                         #fff;
//
$gray-base:                     #000;
$gray-darker:                   lighten($gray-base, 13.5%); // #222
$gray-dark:                     lighten($gray-base, 20%);   // #333
$gray:                          lighten($gray-base, 33.5%); // #555
$gray-light:                    lighten($gray-base, 46.7%); // #777
$gray-lighter:                  lighten($gray-base, 91.0%); // #eee
//
$brand-color:                   $primary-color;
$brand-color-2:                 $secondary-color;
//
$border-color:                  #ddd; //Border color
//
$body-bg:                       $white;
$header-bg:                     $white;
$footer-bg:                     $white;
$nav-bg:                        $white;
$copyright-bg:                  $gray-lighter;
//
$link-gen:                      $brand-color;
$link-gen-hover:                $text-color;
$link-nav:                      $nav-text-color;
$link-nav-hover:                $brand-color;
$link-footer:                   $white;
$link-footer-hover:             $brand-color;
$link-copyright:                $text-color;
$link-copyright-hover:          $text-color;
//
$twitter-color:                 #0088CD;
$facebook-color:                #17356F;
//
$font-family-sans-serif:        'Ubuntu', sans-serif;
$font-sans:                     $font-family-sans-serif;
$font-serif:                    'Noto Serif', serif;
//
$font-default:                  $font-serif;
$font-title:                    $font-sans;
$font-size:                     1em;
//
$hn-color:                      $text-color;
$h1-color:                      $gray-dark;
//
$background-color2:             $brand-color;
$background-color3:             $gray-lighter;

$background-color5:             $white;
$background-color6:             $brand-color;
$background-color7:             #E8EBF0;// was $gray-lighter
$background-color8:             rgba(255, 255, 255, 0.5);
$box-padding:                   1em;
$box-padding-bottom:            0.5em;
//
$spacer:                        $grid-gutter-width;
$spacer-half:                   $grid-gutter-width/2;
//
$nav-height:                    154px;
$nav-height-sticky:             60px;
$nav-font-size:                 1.0em;
//
$pagination-h:                  54px;
$pager-w:                       4rem;
$pager-h:                       6rem;
//
$spacer-clr:                    $gray-lighter;

//BUTTON
$btn-color1:                    #fff;
$btn-color1-txt:                $text-color;
$btn-color2:                    $brand-primary;
$btn-color2-txt:                #fff;
$btn-color3:                    $background-color3;
$btn-color3-txt:                $text-color;

$btn-color5:                    $background-color5;
$btn-color5-txt:                $brand-primary;
$btn-color6:                    $brand-primary;
$btn-color6-txt:                $white;
$btn-color7:                    $background-color7;
$btn-color7-txt:                $text-color;
$btn-color8:                    $background-color8;
$btn-color8-txt:                $text-color;
// default size
$btn-font-size:                 13px;
$btn-radius:                    15px + ceil($btn-line-height * $btn-font-size /2);
$btn-padding:                   15px 40px;
$btn-border-width:              2px;
$btn-line-height:               22px;
$btn-primary-color:             #fff;
$btn-primary-bg:                $brand-primary;
$btn-primary-border:            $brand-primary;
// xs
$btn-xs-font-size:              13px;
$btn-xs-padding:                10px 30px;
$btn-xs-radius:                 8 + ceil($btn-line-height * $btn-xs-font-size /2);
// lg
$btn-lg-font-size:              13px;
$btn-lg-padding:                24px 90px;
$btn-lg-radius:                 24 + ceil($btn-line-height * $btn-lg-font-size /2);

// == == == == == //

$transition-navbar:             all 150ms ease-in-out;


//Boostrap VAR
$screen-sm-min:               768px;
$screen-md-min:               992px;
$screen-lg-min:               1200px;

$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

//== Forms
$input-color-placeholder:     #999;

//== Grid system
$grid-columns:                12;
$grid-gutter-width:           15px;
//== Container sizes
$container-tablet:             (720px + $grid-gutter-width);
$container-desktop:            (940px + $grid-gutter-width);
$container-large-desktop:      (1140px + $grid-gutter-width);

// Font Awesome
$fa-font-path: "../fonts/";

// CSS3 Animations - animate Less
$animationLessTime: 1s;

// Customized Navbar z-index
$zindex-navbar-fixed: 9989;

$grid-float-breakpoint: $screen-md-min;

$container-max-widths: (
  md: $container-tablet,
  lg: $container-desktop,
  xl: $container-large-desktop,
);