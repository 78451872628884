.owl-carousel {
    .slider-item {
        .figure {
            img.no-adjust {
                position:static;
                top:auto;
                left:auto;
                transform:none;
            }
        }
    }
}