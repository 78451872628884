.articlelayout, .newslayout {
  header {
      &:not(.article-header) {
        &:before {
          content: "";
          border-right: 1px  solid $text-color;
          display: inline-block;
          height: 50px;
          margin-left: 10px;
          position: absolute;
          @include transform(rotate(-20deg));
        }
        h1 {
          font-family: $font-serif;
          font-style: italic;
          margin-left: 36px;
          font-size: 2em;
        }
      }

      &.article-header {
          h1 {
              font-weight: bold;
          }
      }

      @media (min-width:$screen-md-min) {
          margin-top: 0;
      }

      .social-list {
          margin-top: $spacer;
      }

      .time {
          font-family: $font-serif;
          font-style: italic;
          font-size: 1em;
      }
      nav.pager-news {
          top: 20px;
          position: absolute;
          left: 0;
          right: 0;
          a {
            color: $brand-color-2;
            &:hover {
              background: $menu-bg;
            }
          }
      }
  }

  .article-header .metadata-tag {
      &, & a {
          color: $brand-color;
          font-family: $font-serif;
          font-style: italic;
          font-size: 1.25em;
      }
  }

  p {
      font-family: $font-sans;
  }

  .lead {
    color: $text-color;
    font-size: 1.125rem;
    font-weight: 700;
  }

  .rte-text h2 {
    font-family: $font-serif;
    font-style: italic;
    padding-left: 36px;
    font-size: 2em;

    &:before {
      content: "";
      border-right: 1px  solid $text-color;
      display: inline-block;
      height: 50px;
      margin-left: 10px;
      position: absolute;
      left: 0;
      @include transform(rotate(-20deg));
    }
  }
}

//if can not access to article
.limited-access {
  background: rgba(255,255,255,0);
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(254,254,254,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(5%, rgba(255,255,255,1)), color-stop(100%, rgba(254,254,254,1)));
  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(254,254,254,1) 100%);
  background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(254,254,254,1) 100%);
  background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(254,254,254,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 10%, rgba(254,254,254,1) 100%);
  padding-top: 100px;
  top: -225px;
  position: relative;
  .bg {
      overflow: hidden;
      padding: 2em !important;
  }

  .access-block {
      h2 {
          font-size: 1.25em;
          margin-bottom: 30px;
      }
      span {
          display: inline-block;
          margin: 0 30px;
      }
      margin-bottom: 40px;
  }
}

.connection-block, .inscription-block {
  .bg {
      overflow: hidden;
      padding: 2em !important;
      background-color: $menu-bg;
  }
  h2 {
      margin-top: 0;
      line-height: 1.5em;
  }
  form {
      margin-top: 20px;
      button {
          margin-right: 8px;
      }
  }
}

.connection-block {
  h2 {
      font-family: $font-serif;
      font-style: italic;
      font-size: 2em;
      margin-left: 36px;
      margin-top: 0;
  }
  .control-label {
      line-height: 2em;
  }
  button {
      margin-right: 8px;
  }
}

.author-block {
  ul {
    display: flex;
    margin-top: $spacer;

    li {
      border-left: 1px solid $border-color;
      padding: 5px 15px;
      margin-left: 15px;
      a {
        font-size: 1.25em;
        color: $primary-color;
      }
    }
  }
}