#p-container {
  @include media-breakpoint-up(lg) {
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 240px;
    margin-top: 50px;
  }

  @include media-breakpoint-down(md) {
    background-image: unset !important;
  }

  .mobile-pub {
    margin-top: 25px;
  }
}

.menu-pub {
  display: inline-flex;
  max-width: 200px;
  cursor: pointer;

  img {
    max-height: 64px;
  }
}