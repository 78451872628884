//title with backslash
.is-title-backslash {
  &:before {
      content: "";
      border-right: 1px  solid $text-color;
      display: inline-block;
      height: 50px;
      margin-left: 10px;
      position: absolute;
      @include transform(rotate(-20deg));
  }
  h1, h2 {
      font-family: $font-serif;
      font-style: italic;
      margin-left: 36px;
      font-size: 2em;
  }
}

.is-title-backslash-s {
  &:before {
    content: "";
      border-right: 1px  solid $text-color;
      display: inline-block;
      height: 30px !important;
      margin-left: 10px;
      position: absolute;
      @include transform(rotate(-20deg));
  }
  h1, h2 {
    font-family: $font-serif;
    font-style: italic;
    font-size: 1.5em !important;
    margin-left: 2em !important;
  }
}

//connection part title type
.is-title-bold {
  font-family: $font-sans !important;
  font-style: normal !important;
  font-weight: bold !important;
  margin-left: 0 !important;
  font-size: 2em !important;
  &:before {
      display: none;
  }
}