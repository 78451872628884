//Remodal Lightbox
@media (min-width:$screen-md-min) {
  .remodal {
      max-width: 960px;
  }
}

.remodal-overlay {
  background: rgba(55, 63, 76, 0.9);
}

.remodal-wrapper {
  right: 0;
  .remodal-close {
      right: 0;
      left: auto;
      color: $brand-color;
  }
}

// Modal
.modal {
  z-index: $zindex-navbar-fixed + 10;
  font-family: $font-sans;
  color: $text-color;

  .modal-footer,
  .modal-header {
      border: 0;
  }
}

.modal-backdrop {
  z-index: $zindex-navbar-fixed + 9;
}

//popin sweetalert
.swal2-container {
  z-index: 9999;
}

.swal2-modal .swal2-title {
  font-size: 1.5em;
}

.swal-modal-signup {
  .swal2-content {
      font-size: 1em !important;
  }
  width: 1000px !important;
}