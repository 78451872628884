.figure.block-fullwidth {
  margin:0 0 $spacer 0;
}
// metadata with border top
.metadata-wrapper.border-top {
  padding-top: 10px;
  margin-top: 10px;
}
.clearboth {
  clear: both;
}

.paragraph, .lead {
  font-family: $font-sans;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cloud-hidden {
  display: none;
}

.form-group {
  overflow: hidden;
  &.required {
      label.control-label:after {
          content:"*";
      }
  }

  .control-label {
      font-family: $font-sans;
      font-weight: 400;
      color: $brand-color-2;
  }
}

.owl-carousel .slider-item .slider-content {
  background: rgba(196, 19, 48, 0.6);
  left: 10%;
  right: 2px;
  top: auto;
  bottom: 0;
  text-align: left;
  padding: 0;
  @include transform(translateY(0));
  [data-parameter-name="title"] {
      font-size: 1.5em;
      font-style: italic;
      font-family: $font-serif;
  }
  p {
      margin-bottom: 0;
  }
  .lead {
      padding: .5em 2em;
      margin: 0;
  }
}

i.icon_pen {
  content: url(../img/Pen.svg);
}

@include media-breakpoint-up(md) {
  .cloud-edit {
      .navbar.navbar-fixed-top.add-padding {
          margin-left: 130px //toolbar + menu-shortcut-width
      }
  }

  .is-desktop.small-screen .cloud-edit .navbar.navbar-fixed-top.add-padding {
      margin-left: 110px //toolbar + menu-shortcut-width
  }
}

.form-control {
  border-radius: 0;
  &.half-width {
      width: 49%;
      float: left;
  }
  &.float-right {
      float: right;
  }

  &:focus {
    box-shadow: none;
  }
}

//globle button
.bb-site-wrapper{
  .btn {
    font-family: $font-sans;
    text-transform: uppercase;
    font-weight: bold;
    border-width: 1px;

    &.btn-primary {
      &, & * {
        color: $white !important;
      }
    }
  }

  .form-action {
    .btn {
      padding: 1rem 5rem;
    }
  }

  .no-radius {
    border-radius: 0;
  }

  .btn-radius {
    border-radius: 3px;
  }

  .btn-brand {
    a.btn {
      @extend .btn-primary;
      padding: 15px 10px;
      width: 100%;
      font-size: 22px;
      font-weight: 400;
    }
    span.is-title-backslash-s {
      font-family: $font-serif;
      display: block;
      font-size: 1rem;
      text-transform: none;
      &:before {
        border-color: $white;
        margin-left: 0;
      }
      em {
        padding-left: 1rem;
      }
    }
  }

  .metadata-wrapper {
    text-transform: initial;

    .metadata-tag {
      padding: 0;
      text-transform: capitalize;
      border: none;
    }

    a {
      color: $primary-color;

      &:hover {
        color: $text-color;
      }
    }
  }

  .breadcrumb {
    color: #677693;
    font-family: $font-sans;
    margin: 10px 0 0;
    display: block;
    background-color: transparent;
  }

  .title {
    margin: 40px 0;
  }

  .metadata-author {
    color: $brand-color;
  }

  .bb-content.quote {
    .quote-text {
        margin: 0 2em;
        padding: 0 1em;
        border-left: 2px solid $brand-color;
        color: $brand-color;
        &.text-right {
            border-left: 0;
            border-right: 2px solid $brand-color;
        }
    }
  }
}

#bb-site-wrapper.blur {
  filter: unset;
}

.bb-content.media-image {
  .contentwrapper .textwrapper {
    background: rgba(196, 19, 48, 0.6);
    padding: .5em 2em;
    left: 10%;
    right: 2%;
    .is-h3 {
      color: $white;
      &[data-parameter-name="title"] p {
        font-style: italic;
        font-family: $font-serif;
      }
      &[data-parameter-name="description"] {
        font-size: 1em;
      }
    }
  }
}
.is-h1,
.is-h2,
.is-h3,
.is-h4,
.is-h5,
.is-h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  &:hover {
    text-decoration: none;
    @include transition($btn-transition);
  }
}

h3, .is-h3 {
  font-size: 1.45rem;

  a:hover {
    color: $primary-color;
    text-decoration: none;
  }
}

a:hover {
  text-decoration: none;
}

.paragraph a {
  &, & span {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.fixed-bottom, .fixed-top {
  z-index: 2;
}

.navbar-header {
  height: unset;
}

.has-bg-color {
  &.color7 {
      @include bg-color($light-color);
  }
}

.rte-text a {
  color: $primary-color;

  &:hover {
    text-decoration: none;
    color: $text-color;
  }
}

.figure.media-image .contentwrapper .above-content-wrapper {
  padding: 0 !important;

  .is-h3 {
    font-weight: 400 !important;
    padding: 0 !important;

    p {
      line-height: 1.2;
    }
  }

  figcaption {
    margin-bottom: 0 !important;
  }

  .textwrapper .is-h3[data-parameter-name=title] {
    margin-bottom: 5px;
  }
}

.form-inline {
  label {
    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
      text-align: right;
    }
  }

  .form-group {
    margin-bottom: 1rem;

    input:not([type=checkbox]):not([type=radio]), select {
      width: 100%;
    }
  }
}

.block-notification {
  ul {
    label {
      display: inline;
      margin-left: 2px; 
      font-weight: 400;
      font-size: 0.8rem;
    }
  }
}
