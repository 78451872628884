// Search bar
.searchbar-wrapper {
  margin-top: $spacer;
  margin-bottom: $spacer;
  padding-bottom: $spacer;
  border-bottom: 1px solid $border-color;
  font-family: $font-sans;
  .search-filter {
    margin-top: $spacer-half;
    p i {
      margin-right: 5px;
    }
  }
  .fa-search {
    position: absolute;
    left: 22px;
    top: 16px;
  }
}

// Search results
.list-result {
  p {
    font-family: $font-sans;
  }

  li {
    padding-bottom: 1em;
    border-bottom: 1px solid $border-color;
    &:last-child {
      border-bottom: 0;
    }
  }

  .tag {
    font-size: 1rem;
    color: $primary-color;
    font-family: $font-sans;
    font-style: initial;
  }

  .time {
    font-family: $font-serif;
  }
}