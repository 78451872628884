// Partage social network
.share-rs {
  .btn-share {
    display: inline-block;
    padding: 10px 0;
    position: relative;

    a {
      color: $text-color;
      border: 1px solid;
      padding: 8px 18px;
      font-family: $font-sans;

      i.fa {
        margin-right: 10px;
        @include transition(none);
      }

      &:hover {
        text-decoration: none;
        background: $text-color;
        color: $white;
      }
    }

    .btn-linkedin {
      color: $white;
      background: #0077b5;
      border-color: #0077b5;
      cursor: pointer;

      &:hover {
        background: darken(#0077b5, 10%);
      }
    }

    .IN-widget > span > button {
      opacity: 0;
      position: absolute !important;
      left: 0;
      right: 0;
      top: 4px;
      padding: 18px 37px !important;
    }
  }
}

// Social Network Links
.social-list {
  li a {
    height: auto;
    width: auto;
    line-height: unset;
  }
}