.calendar-showup {
  .carousel {
      background: $menu-bg;
  }
  .carousel-item {
      padding: 15px;
      min-height: 120px;
      time {
          font-style: italic;
          font-family: 'Noto Serif', serif;
          margin-bottom: 16px;
          color: $text-color;
      }
      h3 {
          font-size: 1.125em;
      }

      .description {
          margin-top: 16px;
      }

      p {
          color: $text-color !important;
      }

      a {
          color: $primary-color;

          &:hover {
              color: $text-color;
              text-decoration: underline;
          }
      }
  }
  .carousel-navigation {
      text-align: right;
      .carousel-control {
          background: #555 !important;
          position: relative;
          text-shadow: none;
          display: inline-block;
          padding: 6px 12px;
      }
  }
}