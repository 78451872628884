main.limited {
    .newslayout,
    .articlelayout {
        overflow: hidden;
    }
    .newslayout {
        height: 600px;
    }
    .articlelayout {
        height: 800px;
    }
}