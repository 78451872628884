.image-liberary {
  .header {
      margin-bottom: $grid-gutter-width * 2;
  }
  .control-label {
      margin-top: 10px;
  }

  i {
      position: absolute;
      right: 30px;
      top: 16px;
  }
}

  //lightbox for image library
  .image-results-wrapper {
    margin: 20px 0;
    .row {
        margin-bottom: $grid-gutter-width * 2;
        .result-item {
            border: 1px solid $border-color;
            .figure.result-image {
                padding-top: 64%;
                overflow: hidden;
                background: $menu-bg;
                img {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    height: 100%;
                    max-width: none;
                    @include transform(translate3d(-50%,0,0) scale(1.35));
                    transform-origin: center top;
                }
            }
            .title-wrapper {
                padding: $grid-gutter-width;;
                h3 {
                    font-size: 1em;
                }
            }
        }
    }
//end of image results wrapper
}

.result-lightbox {
    h1 {
        font-size: 1.125em;
        font-weight: 500;
        margin-bottom: 15px;
    }
    p {
        font-size: .875em;
        font-family: $font-sans;
    }
    .readmore {
        font-size: .875em;
        font-family: $font-sans;
        color: $brand-color;
    }
    h2 {
        font-size: 1.125em;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 15px;
    }
    .row.flex {
      margin-bottom: $grid-gutter-width * 2;
        .result-item {
            border: 1px solid $border-color;
            .figure.result-image {
                padding-top: 64%;
                overflow: hidden;
                background: $menu-bg;
                img {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    height: 100%;
                    max-width: none;
                    @include transform(translate3d(-50%,0,0) scale(1.35));
                    transform-origin: center top;
                }
            }
            .title-wrapper {
                padding: $grid-gutter-width;
                h3 {
                    font-size: 1em;
                }
            }
        }
    }
}

// Image ibrary
.image-results-wrapper {
  .image-library-header {
      .form-inline {
          text-align: center;

          .form-group {
              margin: 0 10px 10px 0;
          }

          button {
              margin-bottom: 10px;
          }

          @media (min-width:$screen-sm-min) {
              display: flex;
              .form-group {
                  flex-grow: 1;
                  .form-control {
                      width: 100%;
                  }
              }
          }
      }
  }
}