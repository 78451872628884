#access-shortcuts-wrapper {
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 9990;
  color: $white;
  font-family: $font-sans;

  @include media-breakpoint-down(md) {
    display: none;
  }

  .form-action button {
      border-radius: 0;
  }

  a.btn {
      text-transform: uppercase;
      font-weight: 700;
  }

  .contribution-text-editor {
      border: 1px solid;
  }

  h1::selection, p::selection {
      background: $gray-lighter;
  }
  .contribution-text-editor{
      &::selection {
          background: $gray-lighter;
      }
      color: $white;
  }

  ul {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999010;
    margin: 0;
    padding: 0;
    height: 0;
    overflow: hidden;
    background-color: #777;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.25);
  }

  ul.nav-shortcut {
      height: 100% !important;
      width: $menu-shortcut-width;
      background: $brand-color-2;
      overflow: inherit;
      display: block;

      li {
          border-bottom: 1px solid #848d9a;
          position: static;
          padding: 0;
          width: $menu-shortcut-width;
          i {
              cursor: pointer;
              &.fa {
                  color: $white;
                  padding: 1em;
                  width: 100%;
                  text-align: center;
              }
              &.icon_pen {
                  width: 18px;
                  margin: 14px;
              }
          }

          &.login {
              position: absolute;
              bottom: 0;
              border-bottom: 0;
              border-top: 1px solid #848d9a;
              @media (min-width:$screen-sm-min) {
                  display: none;
              }
          }

          &.subscription {
              position: absolute;
              bottom: 49px;
              border-bottom: 0;
              border-top: 1px solid #848d9a;
              background-color: $brand-color;
              @media (min-width:$screen-sm-min) {
                  display: none;
              }
          }
      }
  }

  .nav-shortcut-item {
      color: $white;
      font-family: $font-sans;
      .content-panel {
          display: none;
          position: absolute;
          z-index: 990;
          left: $menu-shortcut-width;
          top: 0;
          bottom: 0;
          margin-top: 8em;
          margin-bottom: 8em;
          overflow: auto;

          @media (max-width:$screen-md-min) {
              margin-top: 3em;
          }

          @extend .fadeInLeft;
          @include animation-duration(0.360s);

          &::-webkit-scrollbar {
            width: 4px;
            background: #677693;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #fff;
          }

          .content-wrapper {
              margin: 0 10%;

              @media (min-width:$screen-lg-min) {
                  margin: 0 auto;
                  max-width: 1000px;
              }
              h1 {
                  color: $white;
                  font-size: 1.725em;
              }

              .library-item {
                  .row.d-flex {
                      margin: $grid-gutter-width * 2 0;
                      .result-item {
                          background: $brand-color-2;
                          .figure {
                            padding-top: 64%;
                            overflow: hidden;
                            background: $menu-bg;
                            img {
                                position: absolute;
                                top: 0;
                                left: 50%;
                                height: 100%;
                                max-width: none;
                                @include transform(translate3d(-50%,0,0) scale(1.35));
                                transform-origin: center top;
                            }
                          }
                          .title-wrapper {
                              padding: $grid-gutter-width $grid-gutter-width $grid-gutter-width*2;
                              h3 {
                                  font-size: 1em;
                                  a {
                                      color: $white;
                                  }
                              }
                          }
                      }
                  }
                  .paragraph {
                      margin: 0!important;
                      padding: 5px 0 !important;
                  }
                  .cloud-liner:after {
                      border-color: #737780;
                  }
              }

              .calendar-panel a {
                color: #fff;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
          }
      }

      &.active {
          background: $brand-color;
          .content-panel {
              display: block;
          }
          &:before {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 5px 0 5px 5px;
              border-color: transparent transparent transparent $brand-color;
              position: absolute;
              left: 50px;
              z-index: 1000;
              margin-top: 18px;
          }
      }
  }

  .nav-shortcut-panel {
      position: absolute;
      z-index: 990;
      left: $menu-shortcut-width;
      top: 0;
      bottom: 0;
      background-color: $bg-footer;
      opacity: 0;
      visibility: hidden;
      @include transition(all 0.360s ease-out);
      &.opened {
          opacity: 1;
          visibility: visible;
      }
  }

  .btn-close {
      background: transparent;
      border: none;
      position: absolute;
      right: 0;
      margin: 20px;
      i.icon_close {
          font-size: 2em;
      }
  }

  .searchbar-wrapper {
      .form-group {
          margin-bottom: 0;
      }
      p.toggle {
          cursor: pointer;
          i {
              margin-right: 5px;
          }
      }
      .row {
          position: relative;
          .fa-search {
              color: $white;
              -webkit-transform: scaleX(-1);
              transform: scaleX(-1);
              background: $brand-color;
              padding: 17px;
              top: 0;
              margin-top: 0;
              right: 0;
              left: auto;
              position: absolute;
          }
          &.search-filter {
              margin-top: 15px;
          }
      }
      input[type=text] {
          padding-left: 9pt;
          padding-right: 43px;
      }
  }

  .calendar-panel, .calendar-homepage {
      margin: $grid-gutter-width 0;
      .calendar-item-wrapper {
          border-bottom: 1px solid;
          margin-bottom: 30px;
          padding-bottom: 15px;
          .metadata-wrapper {
            font-size: 1.125em;
            font-family: $font-serif !important;
            font-style: italic;
            position: relative;
            color: $white;

            &:after {
                content: "";
                position: absolute;
                bottom: -1em;
                left: 0;
                height: 1px;
                width: 100px;
                background: $white;
            }
          }
          h3 {
              padding: $grid-gutter-width 0 $grid-gutter-width/3;
              color: $white;
          }
      }
  }

  .image-results-wrapper .result-item {
      border: 0;
  }


}

.calendar-homepage {
  margin: $grid-gutter-width 0;
  .calendar-item-wrapper {
      border-bottom: 1px solid;
      margin-bottom: 30px;
      padding-bottom: 15px;
      .metadata-wrapper {
        font-size: 1.125em;
        font-family: $font-serif !important;
        font-style: italic;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            bottom: -1em;
            left: 0;
            height: 1px;
            width: 100px;
            background: $text-color;
        }
      }
      h3 {
          padding: $grid-gutter-width 0 $grid-gutter-width/3;
      }
      p {
          font-family: $font-sans;
      }
  }
}
